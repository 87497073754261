import React from "react";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";
// import loadable from "@loadable/component";
// import { getLCP } from "web-vitals";

// import Utils from "../utils";
// import MediaCarousel from "../components/organisms/media-carousel";
import { Helmet } from "react-helmet";

// Lattice Components
import Lattice from "lattice/lib/components/Lattice";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import LatticeDevLines from "lattice/lib/components/LatticeDevLines";

// Internal Components
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import MediaSections from "../components/organisms/media-sections";
import Image from "../components/atoms/image";

import StoreContext from "../context/storeContext";
import ShopifyContext from "../context/shopifyContext";
import SEO from "../components/functional/seo";
// import BuzzFeed from "../images/buzzfeed.svg";

// Fixtures
import HomePage from "../fixtures/home-page.json";
import Menus from "../fixtures/menus.json";
import StoreInfo from "../fixtures/store-info.json";

import CONSTANTS from "../constants";

// Styles
import * as headerStyles from "../styles/header-styles.module.scss";

function DemoGrid({ props, dev }) {
  const siteMenus = Menus.data;
  const homePage = HomePage.node;

  const isBrowser = typeof window !== `undefined`;
  const isIFrame = isBrowser && window.self !== window.top;

  if (props) {
    const demoProps = props;
    const bg = demoProps.image;
    return (
      <Lattice className="buffy buffy-demo" hideOverflow>
        {dev ? <LatticeDevLines /> : null}
        <SEO title="Buffy Demo" />
        {isIFrame ? (
          <Helmet>
            <script src={withPrefix("iframeResizer.min.js")} />
          </Helmet>
        ) : null}
        <LatticeGrid {...props}>
          {bg ? (
            <Image
              image={bg}
              alt="Sample grid image."
              className="shared__bg-img grid-demo__image"
              style={{ position: "absolute" }}
              loading="eager"
            />
          ) : null}
          <div className="grid-demo__column">
            <span className="labeltext">1</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">2</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">3</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">4</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">5</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">6</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">7</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">8</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">9</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">10</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">11</span>
          </div>
          <div className="grid-demo__column">
            <span className="labeltext">12</span>
          </div>
          {demoProps.useGutters ? (
            <>
              <div className="grid-demo__column">
                <span className="labeltext">13</span>
              </div>
              <div className="grid-demo__column">
                <span className="labeltext">14</span>
              </div>
            </>
          ) : null}
        </LatticeGrid>
      </Lattice>
    );
  }
  // getLCP(console.log, true);
  return (
    <Lattice className="buffy home buffy-demo" hideOverflow>
      <LatticeDevLines maxRows={1000} />
      <Header
        menus={siteMenus && siteMenus.contentfulMenus.edges}
        announcement={homePage.announcement}
        layout="home"
        extras={{
          storeContext: StoreContext,
          shopifyContext: ShopifyContext,
          reviewStars: StoreInfo.data.storeInfo.edges[0].node.reviewStars,
          headerStyles,
          ...CONSTANTS,
          domain: process.env.DOMAIN,
          OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
          PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
          FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
          KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
          DEV: process.env.DEV,
          skipToMenuTitle: "Comforters",
          skipToMenuId: "acsbMenu",
        }}
      />
      <SEO title="Buffy" />
      {isIFrame ? (
        <Helmet>
          <script src={withPrefix("iframeResizer.min.js")} />
        </Helmet>
      ) : null}
      <main>
        {homePage.mediaSections && homePage.mediaSections.length ? (
          <MediaSections
            type="home"
            sections={homePage.mediaSections}
            extras={{
              OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
              domain: process.env.DOMAIN,
              DIR_MAP: CONSTANTS.DIR_MAP,
              DEV: process.env.DEV,
              KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
              CORS_PROXY: CONSTANTS.CORS_PROXY,
              isIFrame,
            }}
          />
        ) : null}
      </main>
      <Footer
        menus={siteMenus && siteMenus.contentfulMenus.edges}
        extras={{
          domain: process.env.DOMAIN,
          DIR_MAP: CONSTANTS.DIR_MAP,
          CORS_PROXY: CONSTANTS.CORS_PROXY,
          KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
          DEV: process.env.DEV,
        }}
      />
    </Lattice>
  );
}

DemoGrid.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
  dev: PropTypes.bool,
};

DemoGrid.defaultProps = {
  props: null,
  dev: false,
};

export default DemoGrid;
