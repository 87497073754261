// React & Props
import React, { useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Utils
import {
  getMediaGroupProps,
  getCarouselSettings,
  getMediaBlockProps,
} from "../../utils";
// import CONSTANTS from "../../constants";

// Internal Components
const MediaBlock = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-media-block' */ "./media-block"
  ),
);
const MediaCarousel = loadable(() =>
  import(
    /* webpackChunkName: 'quilt-src-components-organisms-media-carousel' */ "./media-carousel"
  ),
);

function MediaSections({
  type,
  sections,
  reviews,
  product,
  grid,
  className,
  extras,
}) {
  const { iconStyles } = extras;
  const [showReviewModal, setShowReviewModal] = useState();
  const [reviewModalContent, setReviewModalContent] = useState();

  const blockHash = {};
  if (!sections) {
    return null;
  }

  return (
    <>
      {sections.map((mediaSection, mediaSectionIndex) => {
        if (mediaSection.mediaBlocks) {
          // Use first media block to determin type
          const mediaBlockType =
            mediaSection.mediaBlocks[0].type || "collection";

          // Load first 3 reviews and collection slides initially
          let carouselSlides = null;
          if (mediaSection.mediaBlocks[0].products) {
            [{ products: carouselSlides }] = mediaSection.mediaBlocks;
          } else if (mediaBlockType === "review" && reviews && reviews.list) {
            carouselSlides = reviews.list;
          }

          let carouselClasses = mediaSection.classNames
            ? [
                ...mediaSection.classNames,
                `${mediaBlockType}__carousel`,
              ].concat(
                mediaSection.useSlider === false
                  ? [`${mediaBlockType}__group`]
                  : [],
              )
            : `${mediaBlockType}__carousel${
                mediaSection.useSlider === false
                  ? ` ${mediaBlockType}__group`
                  : ""
              }`;
          if (className) {
            carouselClasses = Array.isArray(carouselClasses)
              ? carouselClasses.concat(
                  Array.isArray(className) ? className : className.split(" "),
                )
              : carouselClasses
                  .split(" ")
                  .concat(
                    Array.isArray(className) ? className : className.split(" "),
                  );
          }
          const carouselId = mediaSection.idName;

          if (mediaSection.mediaBlocks[0].products) {
            // Collection
            return (
              <MediaCarousel
                className={carouselClasses}
                id={carouselId}
                title={mediaSection.headline}
                summary={mediaSection.summary}
                backgroundColor={mediaSection.backgroundColor}
                backgroundImage={mediaSection.backgroundImage}
                mobileBackgroundImage={mediaSection.mobileBackgroundImage}
                image={mediaSection.image}
                mobileImage={mediaSection.mobileImage}
                grid={grid}
                {...getMediaGroupProps({
                  route: mediaSection.headlineContent,
                  domain: extras && extras.domain,
                  DIR_MAP: extras && extras.DIR_MAP,
                  preview: extras && extras.preview,
                  previewId: mediaSection.id,
                  abState: extras && extras.abState,
                })}
                lazyLoad={Boolean(
                  mediaSectionIndex > 1 || (extras && extras.hasContentAbove),
                )}
                forceRender={extras && extras.isIFrame}
                renderProps={{
                  desktop: -1,
                  mobile: 3,
                  onChange: true,
                  usePartial: true,
                }}
                {...getCarouselSettings(mediaBlockType, {
                  headline: mediaSection.headline || mediaSection.title,
                  // beforeChange: () => {
                  //   if (!carouselInteractions.includes(carouselId)) {
                  //     setCarouselInteractions([
                  //       ...carouselInteractions,
                  //       carouselId,
                  //     ]);
                  //   }
                  // },
                })}
                // settings={getCarouselSettings(mediaBlockType)}
                key={mediaSection.id}>
                {carouselSlides.map((collectionProduct) => {
                  // Handle bundle product
                  if (
                    collectionProduct.id &&
                    collectionProduct.id !== product.id
                  ) {
                    if (collectionProduct.bundles) {
                      const blockProps = {
                        ...getMediaBlockProps({
                          ...collectionProduct,
                          type: mediaBlockType,
                          extras: {
                            ...extras,
                            classes: carouselClasses,
                          },
                          preview: extras && extras.preview,
                          previewId: collectionProduct.id,
                        }),
                      };
                      return (
                        <MediaBlock
                          type="product-card"
                          className={`${type}__product-card`}
                          {...blockProps}
                          lazyLoad={Boolean(
                            mediaSectionIndex > 1 ||
                              (extras && extras.hasContentAbove),
                          )}
                          key={collectionProduct.id}
                        />
                      );
                    }
                    const productColors =
                      extras && extras.colors
                        ? extras.colors.filter(
                            (c) =>
                              c.node.shopifyColorOption.productHandle ===
                              collectionProduct.shopifyProduct.handle,
                          )
                        : null;
                    const blockProps = {
                      ...getMediaBlockProps({
                        ...collectionProduct,
                        type: mediaBlockType,
                        extras: {
                          ...extras,
                          colors: productColors,
                          classes: carouselClasses,
                        },
                        preview: extras && extras.preview,
                        previewId: collectionProduct.id,
                      }),
                    };
                    return (
                      <MediaBlock
                        type="product-card"
                        className={`${type}__product-card`}
                        {...blockProps}
                        lazyLoad={Boolean(
                          mediaSectionIndex > 1 ||
                            (extras && extras.hasContentAbove),
                        )}
                        key={collectionProduct.id}
                      />
                    );
                  }
                  return null;
                })}
              </MediaCarousel>
            );
          }
          // Hide reviews if none exist
          if (
            mediaBlockType === "review" &&
            (!reviews ||
              (reviews && !reviews.details) ||
              (reviews && reviews.details && !reviews.details.numReviews))
          ) {
            return null;
          }
          return (
            <MediaCarousel
              className={carouselClasses}
              id={carouselId}
              title={mediaSection.headline}
              summary={mediaSection.summary}
              backgroundColor={mediaSection.backgroundColor}
              backgroundImage={mediaSection.backgroundImage}
              mobileBackgroundImage={mediaSection.mobileBackgroundImage}
              image={mediaSection.image}
              mobileImage={mediaSection.mobileImage}
              {...getMediaGroupProps({
                route: mediaSection.headlineContent,
                domain: extras && extras.domain,
                DIR_MAP: extras && extras.DIR_MAP,
                preview: extras && extras.preview,
                previewId: mediaSection.id,
              })}
              reviewInfo={
                mediaBlockType === "review" && reviews ? reviews.details : null
              }
              reviewStars={
                mediaBlockType === "review" && reviews
                  ? reviews.images.reviewStars
                  : null
              }
              reviewModal={
                mediaBlockType === "review" && reviews
                  ? {
                      showReviewModal,
                      reviewModalContent,
                      setShowReviewModal,
                    }
                  : null
              }
              lazyLoad={Boolean(
                mediaSectionIndex > 1 || (extras && extras.hasContentAbove),
              )}
              forceRender={extras && extras.isIFrame}
              useWrapper={mediaBlockType === "lifestyle"}
              wrapperComponent={mediaBlockType === "lifestyle" ? "div" : null}
              {...getCarouselSettings(mediaBlockType)}
              useSlider={mediaSection.useSlider !== false}
              // settings={getCarouselSettings(mediaBlockType)}
              key={mediaSection.id}>
              {mediaBlockType === "review" && reviews
                ? carouselSlides.map((review) => {
                    const blockProps = getMediaBlockProps({
                      type: mediaBlockType,
                      review,
                      reviewStar: reviews.images.smallReviewStar,
                      setShowReviewModal,
                      setReviewModalContent,
                      extras,
                      carouselSlider: mediaSection.useSlider !== false,
                      carouselTextColor:
                        iconStyles &&
                        (iconStyles[mediaSection.backgroundColor] ||
                          iconStyles.standardWhite),
                      preview: Boolean(extras && extras.preview),
                    });
                    return (
                      <MediaBlock
                        type={mediaBlockType}
                        className={`${type}__${mediaBlockType}`}
                        {...blockProps}
                        lazyLoad={Boolean(
                          mediaSectionIndex > 1 ||
                            (extras && extras.hasContentAbove),
                        )}
                        key={review.id}
                      />
                    );
                  })
                : (carouselSlides || mediaSection.mediaBlocks).map(
                    (mediaBlock) => {
                      const blockClasses = mediaBlock.classNames
                        ? [
                            ...mediaBlock.classNames,
                            `${type}__${mediaBlock.type}`,
                          ]
                        : `${type}__${mediaBlock.type}`;
                      if (mediaBlock.type === mediaBlockType) {
                        const blockProps = getMediaBlockProps({
                          ...mediaBlock,
                          extras,
                          carouselSlider: mediaSection.useSlider !== false,
                          carouselTextColor:
                            iconStyles &&
                            (iconStyles[mediaSection.backgroundColor] ||
                              iconStyles.standardWhite),
                          preview: extras && extras.preview,
                          previewId: mediaBlock.id,
                        });
                        return (
                          <MediaBlock
                            type={mediaBlock.type}
                            className={blockClasses}
                            id={mediaBlock.idName}
                            {...blockProps}
                            lazyLoad={Boolean(
                              mediaSectionIndex > 1 ||
                                (extras && extras.hasContentAbove),
                            )}
                            key={mediaBlock.id}
                            grid={false}
                          />
                        );
                      }
                      return null;
                    },
                  )}
            </MediaCarousel>
          );
        }
        const mediaBlockType = mediaSection.type;
        if (blockHash[mediaBlockType]) {
          blockHash[mediaBlockType] += 1;
        } else {
          blockHash[mediaBlockType] = 1;
        }

        let blockClasses = mediaSection.classNames
          ? [
              ...mediaSection.classNames,
              `${type}__${mediaBlockType}`,
              `${type}__${mediaBlockType}${blockHash[mediaBlockType]}`,
            ]
          : `${type}__${mediaBlockType} ${type}__${mediaBlockType}${blockHash[mediaBlockType]}`;

        if (className) {
          blockClasses = Array.isArray(blockClasses)
            ? blockClasses.concat(
                Array.isArray(className) ? className : className.split(" "),
              )
            : blockClasses
                .split(" ")
                .concat(
                  Array.isArray(className) ? className : className.split(" "),
                );
        }
        const blockProps = getMediaBlockProps({
          ...mediaSection,
          preview: extras && extras.preview,
          previewId: mediaSection.id,
          extras,
        });
        return (
          <MediaBlock
            type={mediaBlockType}
            className={blockClasses}
            id={mediaSection.idName}
            {...blockProps}
            lazyLoad={Boolean(
              mediaSectionIndex > 1 || (extras && extras.hasContentAbove),
            )}
            key={mediaSection.id}
          />
        );
      })}
    </>
  );
}

MediaSections.propTypes = {
  type: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.any),
  reviews: PropTypes.shape({
    details: PropTypes.objectOf(PropTypes.any),
    list: PropTypes.arrayOf(PropTypes.any),
    images: PropTypes.shape({
      reviewStars: PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      }),
      smallReviewStar: PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      }),
    }),
  }),
  product: PropTypes.shape({
    id: PropTypes.string,
  }),
  grid: PropTypes.bool,
  extras: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

MediaSections.defaultProps = {
  type: "page",
  sections: [],
  reviews: null,
  product: { id: null },
  grid: true,
  extras: null,
  className: "",
};

MediaSections.displayName = "MediaSections";

export default MediaSections;
